@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

/* root styles */
:root {
  /* scrollbar - color */
  --scrollbar-color-primary: #4ac4f3;
  --scrollbar-color-secondary: #5a8697bd;
}

/* scrollbar style for all */

* {
  scrollbar-width: thin;
  scrollbar-color: #05b692d0 inset 0 0 3px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color-secondary);
  outline: 1px solid var(--scrollbar-color-secondary);
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-color-primary);
  outline: 1px solid var(--scrollbar-color-primary);
}
html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
